/*
  Created BY: Prasannadatta kawadkar
  Created On: 14 Nov 2022
  Creation: style file for CashSheet
*/

.body-60 {
  width: 55%;
  padding: 10px 0px 10px 0px;
}

.body-40 {
  width: 45%;
  padding:  10px 10px 10px 0px;
}

.header-style {
  color: #497174;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  padding: 12px;
  border-bottom: 1px solid #D6E4E5;
}

.sub-header-style {
  color: #497174;
  font-weight: bold;
  font-size: 15px;
  padding:4px 4px 4px 4px;
  border-bottom: 1px solid #D6E4E5;
  text-align: left;
  width: 8rem;
}

.sub-header-style-small {
  color: #497174;
  font-weight: bold;
  font-size: 14px;
  padding: 8px 8px 8px 8px;
  border-bottom: 1px solid #D6E4E5;
  text-align: left;
  width: 8rem;
}

.td-style {
  color: #497174;
  font-size: 15px;
  padding: 8px 8px 8px 8px;
  border-bottom: 1px solid #EFF5F5;
  text-align: left;
  width: 8rem;
}

.td-style-center {
  color: #497174;
  font-size: 15px;
  padding: 4px 8px 4px 8px;
  border-bottom: 1px solid #EFF5F5;
  text-align: center;
  width: 8rem;
}

.td-style-no-border {
  color: #497174;
  font-size: 15px;
  padding: 4px 4px 4px 4px;
  text-align: left;
  width: 12rem;
}

.foot-style {
  color: #497174;
  font-weight: bold;
  font-size: 15px;
  padding: 8px;
  text-align: left;
  width: 8rem;
}

.main-foot-style {
  justify-content: space-around;
  display: flex;
  color: #497174;
  font-weight: bold;
  font-size: 15px;
  padding: 8px 16px 8px 16px;
  border-top: 1px solid #D6E4E5;
}

.table-div1 {
  padding: 16px;
  overflow-y: scroll;
  height: 48vh;
  //max-height: 67vh;
}

.table-div2 {
  padding: 8px;
  overflow-y: scroll;
  height: 50vh;
  //max-height: 43vh;
}

.voucher-buttons {
  position: relative;
  bottom: 8px;
  right: 8px;
  text-align: -webkit-right;
}

.footer-cloumn-width {
  width: 13rem;
  color: #497174;
  font-size: 15px;
  padding: 4px 4px 4px 36px;
  text-align: left;
}
