
// Spacing


dd,
dt,
li
	margin-bottom: 0.5rem

fieldset,
input,
select,
textarea
	margin-bottom: 0rem

blockquote,
dl,
figure,
form,
ol,
p,
pre,
table,
ul
	margin-bottom: 0rem
