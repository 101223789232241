@import "normalize"
@import "minicss"
@import "header"
@import "login"
@import "login_1"
@import "utility_1"
@import "modal"

::-webkit-scrollbar
  width: 8px;

@font-face
  font-weight: normal;
  font-style: normal;
  font-family: ecoicons, sans-serif !important;
  src: url("/assets/fonts/ecoicons/ecoicons.eot");
  src: url("/assets/fonts/ecoicons/ecoicons.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/ecoicons/ecoicons.woff") format("woff"), url("/assets/fonts/ecoicons/ecoicons.ttf") format("truetype"), url("/assets/fonts/ecoicons/ecoicons.svg#ecoicons") format("svg");

body
  //overflow-y: auto

html, body
  height 100%
  width 100%
  padding 0
  margin 0
  background-color #f3f5f7
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  font-family: ecoicons, sans-serif !important;
  //overflow-y auto

.box
  box-shadow 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
  background white
  padding 0.8rem !important
  margin-bottom 16px !important
  border-radius 5px
  border 1px solid #bfcfda
  cursor pointer


#loader
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid color-primary;
  width: 75px;
  height: 75px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

#loader-bg
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.3);
  /*
    Modification: Changed z-index to 999 to show over modals
    By: Devang
    Date: 08/07/2023
  */
  //z-index: 2;
  z-index: 999;
  display: none;

.no-padding
  padding: 0!important;

.tile
  cursor: pointer
  &.fix-width
    width: 200px

.tile:hover
  background-color: #e2e2e2 !important

.tile.active
  border-bottom: 6px solid color-logo-blue
  background-color: #e2e2e2 !important

.select {
  position: relative;
  display: inline-block;
}

.header-color-blue
  color: color-logo-blue !important

h6.header-color-blue
  display: inline
  padding: 8px !important

.selected-option
  color: color-logo-blue !important
  font-size: 1.2em !important

.selected-option option {
  color: color-secondary !important;
  font-size: 0.7em !important;
}


.pagination
  margin-top: 0.5em;
  span
   padding-right: 0.5em;

.border-top-color-blue
  border-top: 1px solid color-logo-blue;
  box-shadow:inherit !important;

.hyperlink
  color: #15c!important;
  cursor: pointer;
  &:hover
   color: #000!important;

.tab-menu .fix-width
  padding: 0.5em 0;
.tab-block .span-margin
  margin-left: 0.5rem;
  margin-top: 0

.billwise-finding-issue
 color: color-danger

.billwise-finding-no-isssue
  color: color-success

.vertical-tiles
  width: 180px;
  //height: 60px;
  border: 1px solid color-logo-blue;
  margin: 0.3rem 1rem;
  border-radius: 5px;
  //padding-top: 0.5rem;
  cursor: pointer;
  p
    height: 3rem;
  h3
    color: color-primary;
    font-weight: normal;
    font-size: 1.8rem !important;
  &:hover
    box-shadow: 2px 3px 8px -1px #808080
    transition: box-shadow 0.3s ease-in-out

.vertical-tiles-non-clickable
  width: 135px;
  height: 115px;
  border: 1px solid grey;
  margin: 1.5rem 1rem;
  border-radius: 5px;
  padding-top: 0.5rem;
  color: grey
  p
    height: 55px
    color: grey
  h3
    color: grey

.vertical-tiles-clicked
  width:200px;
  height: 60px;
  border: 1px solid;
  margin: 0.3rem 1rem;
  border-radius: 5px;
  //padding-top: 0.5rem;
  cursor: pointer;
  box-shadow: 2px 3px 8px -1px #808080;
  color: Black;
  background-color: #e2e2e2;
  p
    height: 50px;
  h5
    color: color-primary;

.vertical-small-tiles-clicked
  width:200px;
  height: 60px;
  border: 1px solid;
  margin: 0.3rem 1rem;
  border-radius: 5px;
  //padding-top: 0.5rem;
  cursor: pointer;
  box-shadow: 2px 3px 8px -1px #808080;
  color: Black;
  background-color: #ede1ff;
  p
    height: 50px;
  h5
    color: color-primary;


margin:
.billform-creation-bottom-buttons
  background-color: color-initial;
  padding: 0.5em 1.5em;
  border-bottom: 1px solid color-primary;
  p
    display: inline;
    float: right;
    margin-right: 0.5rem;
    margin-top: 0.5rem;

.border-right-lightgray
  border-right: 1px solid lightgray;

.table-head
  border-bottom: none;
  p:last-child
    margin-left: 11.4em;

.table-left
  box-shadow: none;
  border-bottom: none;
  padding: 0.5rem 2.5rem!important;
  table
    tbody tr.hidden-tr
      visibility: hidden;

.middle-block
  width: 10%;
  background-color: color-initial;
  padding-top: 0.5em;
  button
    width: 100px;
  button:last-child
    margin-top: 0.5em;

.table-right
  box-shadow: none;
  border-bottom: none;
  table
    tbody tr.hidden-tr
      visibility: hidden;

.modal.previewModalStyling
  .modal-content
    margin: 1% auto;
    section.modal-body
      height: 90vh;
      overflow: hidden;
      #printableArea
        height: 75vh;
        overflow-y: auto;
        margin: 1em 0;

.main-navigation
  height: 3.5em;
  .main-navigation-row
    height: 100%
    .main-navigation-block
        background: #f1f1f1;
        padding-top: 6px;
        padding-left: 28px;
        .main-navigation-select
          width: auto;
          background: #f1f1f1;
.newly-added-rows
  background-color: #e1e1e1

.disabled-field {
  cursor: not-allowed;
  background-color: #e2e2e2 !important;
}

.details-heading {
  background-color: #e2e2e2;
  padding: 3px 7px;
  label {
    padding: 4px 0;
    font-size: 1em;
  }
}

.details-info {
  padding: 7px;
}

.border-black {
  border: 1px solid #000;
}

.border-right-black {
  border-right: 1px solid #000;
}

.border-bottom-black {
  border-bottom: 1px solid #000;
}

@-webkit-keyframes spin
  0%
    -webkit-transform: rotate(0deg);
  100%
    -webkit-transform: rotate(360deg);

@keyframes spin
  0%
    transform: rotate(0deg);
  100%
    transform: rotate(360deg);

.active
  color: color-primary

.button-margin-left
  margin-left: 0.2rem
  margin-bottom: 0.2rem

.margin-left-266
  margin-left: 266px
  height: 80vh
  overflow-y: auto

.margin-left-76
  margin-left: 76px
  height: 80vh
  overflow-y: auto

.custom-label {
  background-color: #F7F7F7;
  padding: 5px 10px;
  border-bottom: 1px solid #DDE8EF;
  border-top: 1px solid #DDE8EF;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 10px;
  button {
    float: right;
    margin-top: -4px;
  }
}

.no-spinner {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.text-overflow-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-input-container {
    position: relative;
    //width: 200px; /* Set the width of the container */
    //height: 40px; /* Set the height of the container */
    //border: 1px solid #ccc; /* Set border color */
    //background-color: #f9f9f9; /* Set background color */
    cursor: pointer; /* Change cursor to pointer on hover */
    //display: flex;
    //align-items: center;
  }

  /* Style the custom file input label */
  .file-input-label {
    padding: 10px;
    font-size: 14px;
    color: #333;
  }

  /* Style the custom file input button */
  .file-input-icon {
    margin-right: 10px;
  }

  /* Style the custom file input button */
  .file-input-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0; /* Make the button invisible */
    cursor: pointer; /* Change cursor to pointer on hover */
  }

  /* Remove this container when use*/
.component-title {
  width: 100%;
  position: absolute;
  z-index: 999;
  top: 30px;
  left: 0;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  color: #888;
  text-align: center;
}

.tab-container {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  padding: 2px;

  background-color: #dadadb;
  border-radius: 9px;
}

.indicator {
  content: "";
  width: 130px;
  height: 28px;
  background: #ffffff;
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 2;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  border-radius: 7px;
  transition: all 0.2s ease-out;
}

.tab {
  width: 130px;
  height: 28px;
  position: absolute;
  z-index: 1;
  outline: none;
  opacity: 0;
}

.tab_label {
  width: 130px;
  height: 28px;

  position: relative;
  z-index: 3;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;

  font-size: 0.85rem;
  opacity: 0.6;

  cursor: pointer;
}

.tab--1:checked ~ .indicator {
  left: 2px;
}

.tab--2:checked ~ .indicator {
  left: calc(130px + 2px);
}

.tab--3:checked ~ .indicator {
  left: calc(130px * 2 + 2px);
}

.dropdown-menu {
    display: none; /* Hide dropdown menu */
    background-color: #e2e2e2;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 999;
    width: 160px;
    box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
    top: 35px;
    left: 0;
    border-radius: 5px;
}

.dropdown-menu.open {
    display: block; /* Show dropdown menu when open */
}

.dropdown-menu li {
  display: block;
  padding: 10px;
  border-bottom: 1px solid #c5bebe;
  margin: 0;
  color: #505f5e;
  &:hover {
    color: #eb455f
  }
}

.list-item-active {
  color: #eb455f !important;
}

.menu-toggle {
    background-color: #333;
    color: white;
    padding: 14px 20px;
    border: none;
    cursor: pointer;
}
