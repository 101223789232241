tabs-link-padding ?= .8rem 1.9rem

.tabs
  display flex
  overflow hidden
  overflow-x auto
  white-space nowrap
  box-shadow inset 0 -1px 0 color-logo-blue
  width 100%
  margin-bottom 0.5rem
  li > a
    cursor default
    align-items center
    color color-secondary
    display flex
    padding tabs-link-padding
    vertical-align top
    //box-shadow inset 0 -1px 0 color-secondary
    user-select none
    &:hover
      color color-primary
      box-shadow inset 0 -1px 0 color-primary
      background-color #e2e2e2 !important
  li
    display block
    margin-bottom 0 !important
    &.is-active
      background-color #e2e2e2 !important
      a
        color color-primary
        box-shadow inset 0 -4px 0 color-logo-blue
  ul
    align-items center
    display flex
    flex-grow 1
    flex-shrink 0
    justify-content flex-start
