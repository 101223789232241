
// Typography


b,
strong
  font-weight: bold

p
  margin-top: 0

h1,
h2,
h3,
h4,
h5,
h6
  font-weight: 300
  letter-spacing: -.1rem
  margin: 0

h1
  font-size: 4.6rem
  line-height: 1.2

h2
  font-size: 3.6rem
  line-height: 1.25

h3
  font-size: 2.8rem
  line-height: 1.3

h4
  font-size: 2.2rem
  letter-spacing: -.08rem
  line-height: 1.35

h5
  font-size: 1.8rem
  letter-spacing: -.05rem
  line-height: 1.5

h6
  font-size: 1.6rem
  letter-spacing: 0
  line-height: 1.4

h7
  font-size: 1.2rem
  letter-spacing: 0
  line-height: 1.4
