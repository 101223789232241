.listCard-nohover {
  //box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  box-shadow: 0 1px 3px rgb(0 0 0 / 0%), 0 1px 2px rgb(0 0 0 / 6%);
  min-height: 50px;
  background:fff;
  border-radius: 4px;
  border-bottom: 1px solid rgba(0,0,0,0.051)
}

.listCard {
  //box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  box-shadow: 0 1px 3px rgb(0 0 0 / 0%), 0 1px 2px rgb(0 0 0 / 6%);
  min-height: 50px;
  background:fff;
  border-bottom: 1px solid rgba(0,0,0,0.051)
}

.listCardActive {
	//-webkit-box-shadow: 5px 5px 5px 0px rgba(186,171,186,1);
	-moz-box-shadow: 5px 5px 5px 0px rgba(186,171,186,1);
	//box-shadow: 5px 5px 5px 0px rgba(186,171,186,1);
  //border: 1px solid #3892f9fc !important;
  //background: #cfd2cf;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.listCardActiveList {
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	border-left: 2px solid #696d76;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.listCard:hover {
  box-shadow: 0 3px 5px rgba(0,0,0,0.25), 0 2px 2px rgba(0,0,0,0.22);
}

.listCard.is-loading {
  .msgContainer {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }
}

@keyframes shine {
	to {
		background-position-x: -200%;
	}
}

// modified by Vihang
// modified on 12/05/2022
// modification : list view height correction

.list-card-scrollable-container {
  height: calc(100vh - 149px);
  overflow: hidden auto;
}

.list-card-scrollable-container-extra {
  height: calc(100vh - 125px);
  overflow: hidden auto;
}

.list-card-ribbon {
  width: 15px;
  writing-mode: vertical-lr;
  right: 0;
  top: 0;
  transform: rotate(180deg);
  color: var(--text-white);
}

.list-card-second-ribbon {
  width: 15px;
  writing-mode: vertical-lr;
  right: 15px;
  top: 0;
  transform: rotate(180deg);
  color: var(--text-white);
}

.list-card-third-ribbon {
  width: 15px;
  writing-mode: vertical-lr;
  right: 30px;
  top: 0;
  transform: rotate(180deg);
  color: var(--text-white);
}

.noTasksList {
    margin: 0 auto;
    height: inherit;
    display: flex;
    width: fit-content;
    align-items: center;
}

/*
        modified by Vihang
        modified on 17/05/2022
        modification : added ellipse for list description
  */


.listCardDescription {
    text-overflow: ellipsis;
    width: 147px;
    overflow: hidden;
    white-space: nowrap;
}

/*
        modified by Vipin
        modified on 10/12/2022
        modification : added listcard-th classes for list card header columns, and listcard1-container for list card cells.
*/

.listcard-thead {
  border: none;
  border-bottom: 2px solid #e1e1e1;
  background: #fff;
  z-index: 1;
  padding-top:0px;
  font-size: 12px;
  position: sticky;
  top:0;
  background-color: white;
  border-bottom: 2px solid #e1e1e1;
  //z-index:4;


  .listcard-th:first-child,th:first-child {
    position: sticky;
    left:0;
    }

  .col-head-active {
    background-color: #e8eaed !important;
    border-radius: 3px;

  .hover-icon-1 {
    /*
      Bug: 10 ATC_UI_BG_10 Admin	Consignments Consignments Page	When hovered on column "BOOKING COST" , the down arrow is getting overlapped on the column "EWB EXPIRY"
      Modification: Changed left from -24px to -16px
      By: Devang
      Date: 10/08/2023
    */
    left:-16px;
    bottom:0;
    opacity:1 !important;
    }

  .hover-icon-2{
    /*
      Bug: 10 ATC_UI_BG_10 Admin	Consignments Consignments Page	When hovered on column "BOOKING COST" , the down arrow is getting overlapped on the column "EWB EXPIRY"
      Modification: Changed right from -24px to -16px
      By: Devang
      Date: 10/08/2023
    */
    right:-16px;
    bottom:0;
    opacity:1 !important;
    }
  }

  .stickyTableHead {
   position: sticky !important;
   left: 0;
   z-index: 2;
   /*
    Modification: Removed important on background
    By: Devang
    Date: 10/08/2023
   */
   background: #ffffffd6;
  }



  .listcard-th:hover, th:hover {
    background-color: #e8eaed;
    border-radius: 3px;
    gap:1px;


    .hover-icon-1 {
      /*
        Bug: 10 ATC_UI_BG_10 Admin	Consignments Consignments Page	When hovered on column "BOOKING COST" , the down arrow is getting overlapped on the column "EWB EXPIRY"
        Modification: Changed left from -24px to -16px
        By: Devang
        Date: 10/08/2023
      */
      left:-16px;
      bottom:0;
      opacity:1 !important;
      }

    .hover-icon-2{
      /*
        Bug: 10 ATC_UI_BG_10 Admin	Consignments Consignments Page	When hovered on column "BOOKING COST" , the down arrow is getting overlapped on the column "EWB EXPIRY"
        Modification: Changed right from -24px to -16px
        By: Devang
        Date: 10/08/2023
      */
      right:-16px;
      bottom:0;
      opacity:1 !important;
      }

    }



  .listcard-th,span,div,th {
    font-weight:400 !important;
    color: #3b3b3ba6 !important;
    text-transform: uppercase;
    padding-top: 4px;
    padding-bottom: 4px;
    transition: 0.2s background ease;
    background-color: transparent;
    //font-size: 15px;
    border: none;
    text-align : center;
    cursor : pointer;
    //flex-shrink: 0;
  }

  listcard-th {

  }

}
/*
      modified by Vipin
      modified on 22/12/2022
      modification : Added stickyTableCell for background change on horizontal scroll.
*/
/*
  modified by Komal
  modified on 16/03/2023
  modification : Comment Z-index
*/
.listcard1-container {
  font-size: 13px;


  .listcard-td:first-child,td:first-child {
  text-align: left;
  position: sticky !important;
  left:0;
  //z-index:3;
  }

  .listcard-tr,tr {
      &:hover {
      background-color: #f7f8f9;
      }

   .listcard-td,td {
   font-weight: 400 !important;
   color: #3b3b3b;
   text-align: center;
   padding:0 !important;
   background-color: transparent;
   flex-shrink: 0 !important;
   border: none;
   }

  }

  .stickyTableCell {
   position: sticky !important;
   left: 0;
   background: #ffffffd6 !important;
   border-right: 1px solid #f1f1f1;
  }

  .stickyTableIcons {
  opacity:0.2;
  }

  span,div {
   font-weight: 400 !important;
   color: #3b3b3b;
   //line-height: 1.2 !important;
  }
  .listcard1-case {
    font-weight: 500 !important;
  }

}

.taskview-icons {
  .newWorkSpaceCard {
    min-height: 34px !important;
    min-width: 34px !important;
    height: 34px !important;
    width: 34px !important;
    margin: 2px 2px !important;
  }

}


.tasklist1-input-label::after{
  border: 1px solid #64696d !important;
  background-color: #2a2e34 !important;
  outline: 2px solid #fff;
  outline-offset: -3px;
}


/*
        modified by Vihang
        modified on 04/05/2022
        modification : responsive changes for listcard component
  */
@media (min-width:405px) and (max-width:425px)  {
  .listCardDescription {
    width: 235px;
  }
  /*
          modified by Vihang
          modified on 13/05/2022
          modification : listcard component height corrections for mobile view
    */
  .list-card-scrollable-container {
    height: calc(100vh - 157px);
    overflow: hidden auto;
  }
}

/*
        modified by Vihang
        modified on 18/05/2022
        modification : list description width adjusted for 375 and 325px mobile sizes and height corrections of list cards
  */
@media (min-width:375px) and (max-width:405px)  {
  .listCardDescription {
    width: 190px;
  }
  .list-card-scrollable-container {
    height: calc(100vh - 157px);
    overflow: hidden auto;
  }
}
@media (min-width:320px) and (max-width:374px)  {
  .listCardDescription {
    width: 135px;
  }
  .list-card-scrollable-container {
    height: calc(100vh - 157px);
    overflow: hidden auto;
  }
}

@media (min-width:1023px) and (max-width:1279px)  {
  .listCardDescription {
    width: 128px;
  }
}
