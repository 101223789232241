.body-footer
  width: 100%
  background-color: color-primary
  height: 1.3em
  position: fixed
  bottom: 0
  padding: .1em 0.5em 0.3em
  z-index: 2
  p
    color: color-initial
    text-align: right
    font-size: 0.8rem
    span
      color: color-logo-orange
