.email-input,
.password-input
  font-size 20px
  padding-left 15px

.icon.user,
.icon.password
  margin 5px 10px 0 0

.forgot-password a
  color #95a5a6
  font-weight bold
  padding 0 10px 0 6px

.hero .section
  background-color transparent

.title
  text-transform uppercase
