/*
  Created BY: Prasannadatta kawadkar
  Created On: 12 Dec 2022
  Creation: Toggle Button style file Created
*/

.switchBox {
  margin: 10px;
}

/** Switch
 -------------------------------------*/

.switch input {
  position: absolute;
  opacity: 0;
}

/**
 * 1. Adjust this to size
 */

.switch {
  position: relative;
  font-size: 14px;
  height: 3.75rem;
  width: 14rem;
  background: #FFF;
  border-radius: 4px;
  box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3);
}

.switch-toggle {
  text-align-last: center;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 10px 102px 10px 10px;
  border-radius: 4px;
  justify-content: center;
  background-color: #EF9F9F;
  box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3);
  -webkit-transition: all 300ms;
     -moz-transition: all 300ms;
          transition: all 300ms;
}

.switch-btn2 {
  text-align-last: center;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  margin: 16px 16px 16px 116px;
  justify-content: center;
  color: #252525;
}

.switch-btn1 {
  text-align-last: center;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  margin: 16px 106px 16px 12px;
  justify-content: center;
  color: #252525;
}

.switch input:checked + div {
  background-color: #EF9F9F;
  margin: 10px 105px 10px 10px;
  -webkit-transform: translate3d(120%, 0, 0);
     -moz-transform: translate3d(120%, 0, 0);
          transform: translate3d(120%, 0, 0);
}
