.modal
  display flex; /* Hidden by default */
  position fixed; /* Stay in place */
  z-index 2; /* Sit on top */
  left 0;
  top 0;
  width 100%; /* Full width */
  height 100%; /* Full height */
  overflow auto; /* Enable scroll if needed */
  background-color rgb(0,0,0); /* Fallback color */
  background-color rgba(0,0,0,0.4); /* Black w/ opacity */
  .modal-content
    margin 10% auto; /* 10% from the top and centered */
    padding 0
    width 80%;
    &.is-extra-large
      width: 80% !important
    &.is-large
      width: 60% !important
    &.is-medium
      width: 50% !important
    &.is-small
      width: 40% !important
    &.is-full-height
      height: 97.5vh !important
      margin: 0 auto !important
    header.modal-header
      h6 {
        font-size: 1.3em;

      }
      a {
        font-size: 22px;
      }
      padding 2px 16px
      background-color #fff
      color #ad0b0b
      align-items center
      display flex
      flex-shrink 0
      justify-content space-between
      position relative
      border-bottom: 1px solid #ad0b0b
    section.modal-body
      background-color color-initial
      flex-grow 1
      flex-shrink 1
      overflow auto
      padding 20px
    footer.modal-footer
      text-align right
      background-color color-initial
      flex-grow 1
      flex-shrink 1
      padding 20px
/* The Close Button */
.modal-body-height
  height: 100%

.close
  color #ad0b0b
  float right
  font-size 28px
  font-weight bold

.close:hover,
.close:focus
  color black
  text-decoration none
  cursor pointer

.modal-scrollable
  max-height: 300px;
