/*
  Created BY: Prasannadatta kawadkar
  Created On: 1 Oct 2022
  Creation: style file for kanban
*/
.react-kanban-board {
  display: flex;
  overflow-x: scroll;
}

.react-kanban-column {
    padding:0px 10px 40px 20px;
    border-radius: 2px;
    margin: 16px;
    min-width: 360px;
    height: 56vh;
    border-radius: 10px;
    overflow-y: scroll;
}

.react-kanban-column-header {
    position: sticky;
    top:0;
    padding-bottom: 20px;
    font-weight: bold;
    font-size: 14px;
    color: #252525
    padding-top: 20px;
    //position: absolute;
    z-index: 1;
}

.react-kanban-card {
    border-radius: 4px;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
    max-width: 352px;
    min-width: 312px;
    font-weight: 500;
    border: 1px solid #fff;
    cursor: grab;
    color: #252525;
    transition: margin-top 5s ease;
}

.react-kanban-card :after {
  margin-top: 40px;
}

.react-kanban-card__title {
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
}

.react-kanban-card__title2  {
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
}

.img {
  position: relative;
  top: 6px;
}

.invertedImg  {
  transform: scaleY(-1);
}

.dropdown {
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
}

.displayNone  {
  display: none;
}

.kanbanPill {
  cursor: pointer;
}

.kanbanPillsDiv :hover {
  color: blue;
}

.selectedPill {
  color: violet;
}

.next-img {
  right: 10px;
}

.dragging {
  opacity: 0.5;
}
