#progress {
  padding: 0;
  list-style-type: none;
  font-family: arial;
  font-size: 12px;
  clear: both;
  line-height: 1em;
  margin: 0 -1px;
  text-align: center;
}

#progress li {
  float: left;
  padding: 10px 30px 10px 40px;
  background: #d5caea;
  color: #000;
  position: relative;
  border-top: 1px solid #d5caea;
  border-bottom: 1px solid #d5caea;
  width: auto;
  margin: 0 1px;
}

#progress li:before {
  content: '';
  border-left: 16px solid #fff;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  position: absolute;
  top: 0;
  left: 0;

}
#progress li:after {
  content: '';
  border-left: 16px solid #d5caea;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 20;
}

#progress li.active {
  background: #2cb22c;
  color: #fff;
}

#progress li.active:after {
  border-left-color: #2cb22c;
}
