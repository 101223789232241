.toast
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10000;
  max-width: 100%;
  transition: all 0.3s ease;

.toast > .body
	font-size: initial;
	margin: 0 1em 1em 1em;
	padding: .5em;
	word-wrap: break-word;
	background: color-secondary;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
	color: white;

.toast > .body.info
	background: #03A9F4;

.toast > .body.warning
	background: color-warning;

.toast > .body.error
	background: color-danger;

@media screen and (max-device-width : 767px)
	.toast
		width: 100%;
		> .body
			margin: 0;
