.newWorkSpaceCard {
  //background: var(--card-primary-color);
  border-radius: 4px;
  min-height: 40px;
  height: 33px;
  border: 1px solid #ccc;
  color: #000;
  font-weight: 500;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  .newWorkSpaceCard-count {
    //color: var(--card-primary-count);
    color: #808080;
    font-size: 16px;
  }
  .newWorkSpaceCard-header {
    //color: var(--card-primary-header);
    color: #fff;
    font-size: 12px;
    margin-top: 4px
  }
  .div-back {
    transform: rotateY(180deg);
  }
  .div-front, .div-back {
    //position: absolute;
    //width: 100%;
    //height: 100%;
    backface-visibility: hidden;
  }
}
.newWorkSpaceCard-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  svg {
    height: 24px;
    width: 24px;
  }
}

.newWorkSpaceCard:after {
  content: attr(data-label);
  position: absolute;
  top: -7.5px;
  left: 5px;
  background: var(--theme-default-colour);
  color: #fff;
  font-size: 10px;
  padding-right: 5px;
  padding-left: 2px;
}
.subfilterCard {
  //box-shadow: rgb(0 0 0 / 5%) 0.5px 0.5px 0.5px 0.5px;
  border-radius: 4px;
  background: var(--card-secondary-color);
  .subfilterCard-count {
    color: var(--card-secondary-count);
  }
  .subfilterCard-header {
    color: var(--card-secondary-header);
  }
}
.subfilterCardActive {
  box-shadow: 2px 2px 5px 0px #baabba;
}
.Overviewworkshop {
  height: calc(100vh - 130px);
  position: relative;
  padding-bottom: 40px;
  padding-left: 2px;
}
.semi-detail-summary {
  padding-top: 13px;
}
.semi-detail-footer {
  position: absolute;
  bottom: 0px;
  right: 0px;
}


@media (min-width:320px) and (max-width:425px)  {
	/* smartphones, iPhone, portrait 480x320 phones */
  .isDesktop {
  		display:none
  }
  .is-hide-mobile {
    display:none
  }
  .headerWorkspaceHeadText {
    font-size:16px !important;
  }
  .priceListButtonText {
    font-size:12px !important
  }
}

@media (min-width:426px) and (max-width:768px)  {
	/* smartphones, iPhone, portrait 480x320 phones */
  .isDesktop {
  		display:none
  }
  .is-hide-mobile {
    display:none
  }
  .headerWorkspaceHeadText {
    font-size:16px !important;
  }
  .priceListButtonText {
    font-size:12px !important
  }
}

/*
      modified by Vipin
      modified on 15/12/2022
      modification : Added Outer1, custom1, search, search-transition,globalModal-transtion Classes
      to the dayPlanWorkspace and ListCard1 component fors new task view.
*/

.outer1{
  display: flex;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  background: #E5DCD3;
  padding: 10px;
  height: auto;
  border-radius: 4px;
}

.outer1{
   table {
    table-layout:fixed;
    width: 100px;
    border: none;
   }
}


.search-transition-on {
   transform: scaleY(1);
  transform-origin: top;
  transition: 0.2s transform ease-out !important;
  background-color: #fff !important;
  padding: 0 !important;

  div {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  }

  li:hover {
  background-color: #e7e9ec;
  }
}

.search-transition-off {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.1s transform ease-in !important;
  background-color: #fff !important;
  padding-top: 0 !important;
}

.globalmodal-transition-on {
  height: 100%;
  width: 100%;
  z-index: 1000;
  position:absolute;
  top:-6%;
  left:-8px;
  transform: scaleY(1);
  transform-origin:top;
  transition: 0.2s transform ease-in;
}

.globalmodal-transition-off {
  height: 100%;
  opacity:0;
  width: 100%;
  z-index:1000;
  position: absolute;
  top:-6%;
  transform: scaleY(0);
}

.search-content {
  min-width: 20rem;
  position: relative;
}

.search {
  --size: 36px;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  font-size: 1.25em;
  position: relative;
  width: var(--size);
  height: var(--size);
  transition: width 350ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
  padding: 4px;
  width: 100%;
  border:1px solid #e1e1e1;

  button {
  min-width:22px;
  padding:3px;

  }
}

#search__input {
  border: 0;
  padding: .25em 1em !important;
  flex-grow: 1;
  outline: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: transparent;
  color:black !important;
  opacity: 1;
  z-index: initial;
  cursor: initial;
  width: calc(100% - var(--size));
  text-transform:capitalize;
  font-size:14px;
}


.search__submit {
  position:relative;
  font-size: 1.5rem;
  margin-left: auto;
  margin-right:12px;
  background: 0;
  border: 0;
  cursor: pointer;
  border-radius: 50%;
  transition: background 200ms ease-out;
  width: calc(var(--size) - 10px);
  height: calc(var(--size) - 10px);
  background: #e5dcd3;
  background: #f7f7f7;
  color: black;
  box-shadow: 0 0 10px rgba(0,0,0,.15);
  svg{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    height:16px;
    width:18px;
  }

}

.search:focus-within {
  background-color: #f7f8f9;
  ::placeholder {
  font-size:14px;
  }
  #search__input {

  }
  .search__submit {
    //border: 1px solid #0f3868;
  }
  .search__submit:hover,
  .search__submit:focus {
    outline: 0;
  }
  *:focus{
  outline:0;
  }
}

.workspace-filter{
border: 1px solid #e1e1e1;
border-radius: 4px;
min-width:20rem;
flex-basis: auto;
flex-direction:row-reverse;
align-items: center;

}


.task-col1{
  top:24px;
  right: -190px;
}

.task-col2{
  top:24px;
  right: -190px;
}

.task-col3{
  top:24px;
  right: -190px;
}

.task-col4{
  top:24px;
  right: -190px;
}

.task-col5{
  top:24px;
   right: -190px;
}

.task-col6{
  top:24px;
   left: -100px;
}

.addColumn {
left: -164px;
top: 24px;
}

.addColumn {
left: -164px;
top: 24px;
}

.addColumns {
 width: 24px;
 height: 39px;
 right: 8px;
 top: 0px;
 position: absolute;
 z-index: 2;
 background-color: #d6d8db;
 padding-top: 8px;
}

.custom1 {
  position: absolute;
  width: 180px;
  height: 298px;
  overflow: hidden;
  background: #f9f9f9;
  font-size: 12px !important;
  font-weight: 400;
  color: #3b3b3b;
  z-index: 9999;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

  li {
    display: flex;
    gap: 1rem;
    padding-left: 8px;
  }

  label,input {
    font-size: 12px !important;
    color: #3b3b3b !important;
    font-weight: 400 !important;
  }

  [type="checkbox"] + label::before{
  top: 3px;
  left: 0;
  width: 14px;
  height: 14px;
  border: 2px solid #5a5a5a;
  font-size: 12px !important;
  }

  [type="checkbox"]:checked + label::before {
  top: 0px;
  left: -5px;
  width: 9px;
  height: 16px;
  border-right: 2px solid #5a5a5a !important;
  border-bottom: 2px solid #5a5a5a !important;
  font-size: 12px !important;
  }

  .apply-filter-btn {
    width:105%;
    padding: 13px 0;
    margin-top:-8px;
    border-radius: 0px;
  }

  #custom-input {
    outline: none;
    border: none;
    font-size: 13px !important;
    width: auto !important;
  }

  .custom-container {
    height: calc(100% - 54px);
  }
  .custom-item {
    background: #fff;
    cursor: pointer;
    &:hover {
      background: #f7f8f9;
    }
  }
}

.custom-select {
  position: absolute;
  width: 233px;
  border-radius: 12px;
  height: 45px;
  overflow: hidden;
  padding-left: 15px;
  top:152px;
  left:966px;
  font-size: 16px;
  font-weight: 400;
  color: #2a2e34;
  background-color: #E5DCD3;
  animation: 0.25s ease-out 0s 1 growOutCustomSearchSelect;

  #custom-select-input {
    outline: none;
    border: none;
    font-size: 14px;
    width: auto !important;
  }

  .custom-select-container {
    height: calc(100% - 54px);
  }
  .custom-select-item {
    background: #fff;
    cursor: pointer;
    &:hover {
      background: #f7f8f9;
    }
  }
}


@media (min-width:1024px) {
	/* big landscape tablets, laptops, and desktops */
  .isMobile {
      display:none
  }

  .is-hide-Desktop {
      display:none !important
  }
}

/*modified by Vihang
   modifield on 16/05/2022
   modification: display car container added  for display car checklist
*/
.display-car-container {
  height: calc(100vh - 200px);
}
