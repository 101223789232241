.header
  width: 100%
  //min-height: 5rem
  height: 3.2em
  padding: 0.5em 1.5rem 0 1.5rem
  background: #2C1D4A
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5)
  //border-bottom: 5px solid #656565
  z-index: 2
  div
    //display: flex
    //justify-content: space-between

@media (min-width: 40.0rem)
  .header
    display: flex
    justify-content: space-between
    .menu
      display: block
  .header a.hamburger
    display: none



.global-search {
  input[type="text"] {
    height: 2.2em
    margin-bottom: 0 !important
    background-color: #fff
  }
  ::-webkit-input-placeholder {
    font-size: 14px !important
  }
  ::-moz-placeholder {
    font-size: 14px !important
  }
  placeholder {
    font-size: 14px !important
  }
}

.dropbtn {
  background-color: #d5caea;
  color: white;
  padding: 3px 8px 0 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  height: 31px !important;
}

.dropbtn:hover, .dropbtn:focus {
  background-color: #d5caea;
}

.dropdown-1 {
  position: relative;
  display: inline-block;
  margin-right: 15px;
}

.dropdown-content-1 {
  display: none;
  position: absolute;
  background-color: #fff;
  margin-top: 7px;
  min-width: 200px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  right: 0;
}

.dropdown-content-1 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  &:first-child {
    border-bottom: 1px solid #DDE8EF;
  }
}

.dropdown-1 a:hover:not(first-child) {
  background-color: #ddd;
}

.show {
  display: block;
}
