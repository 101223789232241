.invoice-bills {
  @media abstract {
    .billInvoice {
      border: 0px !important;
      page-break-after: avoid;
    }
  }

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    font-size: 0.9rem;
  }

  td {
    padding: 0 5px;
  }

  .item-table td {
    border: none;
    border-right: 1px solid;
    height: 30px;
  }

  .item-table .item-body tr:last-child {
    height: 318px;
    vertical-align: top !important;
    border-bottom: 1px solid;
  }

  .heading {
    text-align: center;
  }

  .container {
    border: 2px solid;
    height: 700px;
  }

  .companyInfo {
    border-bottom: 2px solid;
    border-right: 2px solid;
    width: 40%;
    height: 100px;
    display: inline-block;
  }

  .clientInfo {
    border-bottom: 2px solid;
    width: 60%;
    height: 100px;
    display: inline-block;
  }

  .table-bill td {
    border-left: .1rem solid #e1e1e1;
    border-right: .1rem solid #e1e1e1;
  }

  //table for bill Receipt

  .table-bill tbody {
      height: 100vh !important
    }

  .table-bill td {
    &:first-child {
      border-left: none
      }
    &:last-child {
      border-right: none
      }
    }

  .table-bill {
    border-spacing: 0
    border-collapse: collapse;
    tr {
      height:25px !important
    }
    td,
    th {
      //border-: 1rem solid #e1e1e1;
      border-collapse: collapse
      font-size: 0.8rem
    }
    td {
      padding: 0 5px
    }
  }
  .newlist-table {
  table-layout: fixed;
  width: 100px !important;
  border: none !important;
  border-spacing: 0 !important;
  border-collapse: separate !important;
}
}
.writeOffBill {
  background: #403e3e;
  color: #fff !important;
  padding: 0 5px;
}
.client-select {
  max-height: 150px;     /* Adjust the height to your needs */
  overflow-y: auto;      /* Enables vertical scrolling when options exceed max-height */
}
{/*
  Modified By: Akshay Chavan
  Modified On: 23 Jan 2025
  Modification: Added height and overflow property for scroll bar.
*/}
