/*
  Created BY: Prasannadatta kawadkar
  Created On: 13 Dec 2022
  Creation: style file for sideMenu
*/

.menu-wrapper {
  transform: translateX(0px);
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 200px;
  padding: 0;
  height: 100%;
  background-color: #fff;
  margin-right:0px;
  box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3);
  overflow: scroll;
}

.list-style {
  list-style: none;
  padding: 2px 12px;
}

.list-item
  padding: 4px;
  font-size: 14px;
  cursor: pointer;
  fill: #7D9D9C;
  color: #7d9d9c;
  &:hover
    cursor: pointer;
    color: rgba(235, 69, 95, 1);
    fill: rgba(235, 69, 95, 1);

.list-item-hover {
  cursor: pointer;
  color: rgba(235, 69, 95, 1);
  fill: rgba(235, 69, 95, 1);
}

.list-item-selected
  padding: 6px 4px 2px 4px;
  font-size: 15px;
  background-color: rgba(235, 69, 95, 1);
  border-radius: 4px;
  color: #FFF;
  fill: #FFF;
  box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3);
  &:hover
    cursor: pointer;
    color:#FFF;
    fill:#FFF;


.list-item-selected :hover {
  cursor: pointer;
  color:#FFF;
  fill:#FFF;
}

.font-color-white {
  color: #FFF;
}

.font-color-grey {
  color: #7D9D9C;
}

.align-text-svg {
  position: relative;
  bottom: 4px;
  left: 8px;
  font-weight: bold;
}

.align-text-svg :hover {
  color: rgba(235, 69, 95, 1);
  fill: rgba(235, 69, 95, 1);
}

.company {
  padding: 16px;
}

.company-logo {
  width: 30px;
}

.company-name {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  bottom: 5px;
  left: 8px;
  cursor: default;
  color: #413F42;
}

.list-heading {
  color: #576F72;
  cursor: default;
  font-size: 12px;
  font-weight: bold;
}
.profile-card {
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.profile-pic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 5px;
    background: #e6eded;
    padding: 3px 8px;
    font-size: 1.2rem;
    text-transform: capitalize;
}

.profile-info h2 {
  font-size: 0.8rem;
  letter-spacing: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
  color: #464646;
}

.profile-info p {
    margin: 0 0 1px 0;
    color: #777;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 140px;
}
