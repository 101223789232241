/*
  Created By: Prasannadatta Kawadkar
  Created On: 20 Dec 2022
  Creation: created class for Dropdown
*/

.dropdown-wrapper {
  width: 20rem;
  border: 1px solid #e2e2e26e;
  box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3);
  border-radius: 4px;
}

.dropdown-label {
  white-space: nowrap;
  width: fit-content;
  color: #252525;
  padding: 8px;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  top: 6px;
  left: 4px;
}

.dropdown-select {
  width: 80%;
  border-radius: 4px;
  color: #252525;
  background: #FFF;
  margin: 8px;
  padding: 0px;
  cursor: pointer;
}
