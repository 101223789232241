/*
  Created By: Prasannadatta Kawadkar
  Created On: 11 Oct 2022
  Creation: style file for manifest
*/
.selectedPill {
  color: #ad0b0b
}

.pillLeft {
  font-size:1.3rem;
  padding-right: 20px;
  cursor: pointer;
}

.pillRight {
  font-size:1.3rem;
  padding-left: 20px;
  cursor: pointer;
  border-left: 1.5px solid #ad0b0b;
}

.manifest-table-data {
  color: #FFF;
  font-size: 15px;
  padding: 8px 8px 8px 36px;
  //border-bottom: 1px solid #eff5f5;
  text-align: left;
  width: 19rem;
}

.manifest-header {
  color: #497174;
  font-weight: bold;
  font-size: 15px;
  padding: 8px 8px 8px 36px;
  border-bottom: 1px solid #d6e4e5;
  text-align: left;
  width: 19rem;
}

.manifest-sub-header {
  color: #FFF;
  font-weight: bold;
  font-size: 15px;
  padding: 8px 8px 8px 36px;
  border-bottom: 1px solid #d6e4e5;
  text-align: left;
  width: 19rem;
}

.manifest-data {
  color: #497174;
  font-size: 15px;
  padding: 8px 8px 8px 36px;
  //border-bottom: 1px solid #eff5f5;
  text-align: left;
  width: 19rem;
}

.height-for-manifest-box {
  height: 65vh;
  overflow-y: scroll;
}
