/*
Modified By: Prasannadatta Kawadkar
Modified On: 14 Dec 2022
Modification: created styl file for Card
*/

.main-title {
  color: #252525;
  padding: 8px;
  font-size: 14px;
  font-weight: bold;
}


.float-no {
  font-size: 13.5px;
  font-width: bold;
  position: relative;
  left: 8px;
  border-radius: 4px;
  background-color: rgb(250, 234, 72);
  color: #252525;
  padding: 4px;
}

.sub-title {
  color: #7d9d9c;
  font-size: 12px;
  font-weight: bold;
  position: relative;
  left: 4px;
}

.sub-title-count {
  font-size: 13px;
  position: relative;
  left: 12px;
  padding: 4px;
  font-weight: bold;
  border-radius: 4px;
}

.money-count {
  color: #413f42;
  font-size: 16px;
  font-weight: bold;
}

.red-dot {
  height: 8px;
  width: 8px;
  background-color: #EB6440;
  border-radius: 50%;
  display: inline-block;
}

.color-red {
  color: #EB6440;
}

.bg-red {
  color: #EB6440;
  background: #eb644013;
}

.green-dot {
  height: 8px;
  width: 8px;
  background-color: #008000;
  border-radius: 50%;
  display: inline-block;
}

.color-darkgreen {
  color: #008000;
}

.bg-darkgreen {
  color: #008000;
  background: #00800013;
}

.darkgrey-dot {
  height: 8px;
  width: 8px;
  background-color: #576F72;
  border-radius: 50%;
  display: inline-block;
}

.color-darkgrey {
  color: #576F72;
}

.bg-darkgrey {
  color: #576F72;
  background: #576F7213;
}
