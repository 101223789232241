@charset "UTF-8";

@font-face {
  font-family: "fontastic-icons";
  src:url("/assets/static/fonts/fontastic-icons.eot");
  src:url("/assets/static/fonts/fontastic-icons.eot?#iefix") format("embedded-opentype"),
    url("/assets/static/fonts/fontastic-icons.woff") format("woff"),
    url("/assets/static/fonts/fontastic-icons.ttf") format("truetype"),
    url("/assets/static/fonts/fontastic-icons.svg#fontastic-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "fontastic-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
  color: color-initial;
  margin-right: 0.4em;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontastic-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-icon].initial:before,
[class^="icon-"].initial:before,
[class*=" icon-"].initial:before {
  color: color-inittial !important;
}

[data-icon].primary:before,
[class^="icon-"].primary:before,
[class*=" icon-"].primary:before {
  color: color-primary !important;
}

[data-icon].is-large:before,
[class^="icon-"].is-large:before,
[class*=" icon-"].is-large:before {
  font-size: 1.2em !important;
}

[data-icon].is-small:before,
[class^="icon-"].is-small:before,
[class*=" icon-"].is-small:before {
  font-size: 1em !important;
}

.icon-ios-contact:before {
  content: "\61";
}
.icon-plus:before {
  content: "\69";
}
.icon-edit-modify-streamline:before {
  content: "\62";
}
.icon-trash-b:before {
  content: "\68";
}
.icon-android-time:before {
  content: "\6c";
}
.icon-navicon:before {
  content: "\6f";
}
.icon-filter:before {
  content: "\6a";
}
.icon-search:before {
  content: "\74";
}
.icon-eye:before {
  content: "\75";
}
.icon-letter-mail:before {
  content: "\78";
}
.icon-home:before {
  content: "\41";
}
.icon-flag:before {
  content: "\42";
}
.icon-page-multiple:before {
  content: "\43";
}
.icon-rupee:before {
  content: "\47";
}
.icon-call-phone:before {
  content: "\4a";
}
.icon-android-create:before {
  content: "\4e";
}
.icon-refresh:before {
  content: "\71";
}
.icon-times:before {
  content: "\4f";
}
.icon-check:before {
  content: "\52";
}
.icon-paper-plane-o:before {
  content: "\64";
}
.icon-user-times:before {
  content: "\65";
}
.icon-users:before {
  content: "\6e";
}
.icon-user:before {
  content: "\66";
}
.icon-book:before {
  content: "\6d";
}
.icon-file-text:before {
  content: "\70";
}
.icon-book-1:before {
  content: "\67";
}
.icon-calendar:before {
  content: "\6b";
}
.icon-mobile:before {
  content: "\72";
}
.icon-phone:before {
  content: "\73";
}
.icon-map-marker:before {
  content: "\76";
}
.icon-plane:before {
  content: "\79";
}
.icon-envelope-o:before {
  content: "\7a";
}
.icon-bicycle:before {
  content: "\44";
}
.icon-flag-checkered:before {
  content: "\45";
}
.icon-flag-o:before {
  content: "\46";
}
.icon-trash-o:before {
  content: "\48";
}
.icon-fire:before {
  content: "\49";
}
.icon-exclamation-triangle:before {
  content: "\4b";
}
.icon-ios-bell:before {
  content: "\4c";
}
.icon-ios-people-outline:before {
  content: "\63";
}
.icon-cogs:before {
  content: "\77";
}
.icon-person-stalker:before {
  content: "\4d";
}
.icon-bullhorn:before {
  content: "\50";
}
.icon-checkmark:before {
  content: "\54";
}
.icon-crossmark:before {
  content: "\2717";
}
.icon-android-checkbox-outline:before {
  content: "\55";
}
.icon-android-checkbox-outline-blank:before {
  content: "\56";
}
.icon-android-checkbox:before {
  content: "\51";
}
.icon-android-checkbox-blank:before {
  content: "\53";
}
.icon-header:before {
  content: "\57";
}
